<template>
  <div>
    <ServicesOffered
        :with-image="false"
        title="Servizi offerti"
        icon-name="caf-white-ico.svg"
        :description="`
        Affidati ad un professionista per la gestione documentale dei servizi di cui hai bisogno:
Servizi Caf e Dichiarativi, Immobili, Camerali, Consulenza e molto altro.
        `"
        :services="services"
    />
    <SectionEmptyImage
        :image-name="'bg-section-default.jpg'"
        :cards="cards"
    />

  </div>
</template>

<script>
import ServicesOffered from "@/components/services/ServicesOffered";
import SectionEmptyImage from "@/components/sections/section-image-empty/SectionEmptyImage";

export default {
  name: 'CafAndCgn',
  components: {
    SectionEmptyImage,
    ServicesOffered,
  },
  data() {
    return {
      cards: [
        {
          id: 1,
          title: 'consulenza del lavoro',
          iconName: 'ico-cons',
          url: '/services/consulenza-lavoro'
        },
        {
          id: 2,
          title: 'consulenza fiscale e contabile',
          iconName: 'calcolatrice-ico',
          url: '/services/consulenza-fiscale-contabile',
          width: 75,
        }
      ],
      services: [
        {
          id: 1,
          title: `Modello 730`,
          description: 'Elaborazione, stampa e presentazione MODELLO 730 con la gestione documentale di tutta la documentazione a supporto e della dichiarazione stessa con archivio permanente.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 2,
          title: `IMU`,
          description: 'Elaborazione, stampa e presentazione MODELLO F24 per versamento IMU con possibilità di richiesta e rilascio di Visura Catastale.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 3,
          title: `DICHIARAZIONE DI SUCCESSIONE`,
          description: 'Elaborazione, stampa e presentazione telematica della Dichiarazione di Successione con relativa archiviazione della stessa della ricevuta telematica e del modello di addebito delle relative imposte indirette.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 4,
          title: `ASSISTENZA FAMILIARE`,
          description: 'Elaborazione e stampa Cedolino per Badanti e Colf con calcolo dei contributi da versare.\n' +
              'Emissione della Certificazione Unica, predisposizione per le pratiche di Assunzioni e Cessazioni di\n' +
              'rapporti di lavoro.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 5,
          title: `MODELLI RED`,
          description: 'Elaborazioni MODELLI RED e Invalidi Annuali.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 6,
          title: `VISURE CAMERALI`,
          description: 'Richiesta e stampa Visure Camerali Parziali o Complete.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 7,
          title: `CONTRATTI DI LOCAZIONE`,
          description: 'Studio, elaborazione, stampa e presentazione telematica di Contratti di Locazione con predisposizione e addebito del MODELLO F23 telematico. Archiviazione del contratto e della ricevuta del versamento.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 8,
          title: `Modello F23 Telematico - Rinnovi e cessazioni`,
          description: 'Elaborazione, stampa e presentazione Telematica per rinnovi annuali di Contratti di Locazione o di Cessazione dei stessi, con le relative predisposizioni del modello F23 e addebito delle imposte indirette.',
          iconName: 'mani-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
      ]
    }
  }
};
</script>