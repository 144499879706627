<template>
  <section class="full-image my-5">
    <img
        :src="require(`@/assets/images/${imageName}`)"
        class="w-100 h-100"
        :alt="imageAlt"
    />
    <div class="container section-cards" v-if="cards">
      <div class="row h-100">
        <div class="col-md-6 h-100 d-flex justify-content-center justify-content-md-end align-items-center">
          <ServiceCard
            :icon-name="cards[0].iconName"
            :title="cards[0].title"
            :url="cards[0].url"
            :width="cards[0].width"
          />
        </div>
        <div class="col-md-6 h-100 d-flex justify-content-center justify-content-md-start align-items-center">
          <ServiceCard
              :icon-name="cards[1].iconName"
              :title="cards[1].title"
              :url="cards[1].url"
              :width="cards[1].width"
          />
          <div class="position-relative text-white p-4" style="z-index: 1">
            <h1 class="fw-bold ">
              Scopri
            </h1>
            <h3>
              gli ulteriori servizi:
            </h3>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import ServiceCard from "@/components/cards/ServiceCard";

export default {
  name: "SectionEmptyImage",
  components: { ServiceCard },
  props: {
    imageName: String,
    imageAlt: String,
    cards: Array,
  }
}
</script>

<style scoped>
  .full-image {
    position: relative;
    width: 100%;
    height: 700px;
  }

  .full-image img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }

  .section-cards {
    height: 50%;
  }

  @media screen and (min-width: 992px) {
    .full-image {
      height: 420px;
    }
    .section-cards {
      height: 100%;
    }
  }

</style>