<template>
  <div>
    <ServicesOffered
        :with-image="true"
        title="Servizi offerti"
        icon-name="fiscale-ico.svg"
        image-name="fiscale.png"
        :description="`
        Lo studio offre diversi servizi di consulenza contabile e fiscale in base alle esigenze del cliente:
Contabilità semplificata, Dichiarazioni Fiscali, Bilanci, Fatturazione Elettroniche e molto altro.
        `"
        :services="services"
        :excerpt="`Contabilità e Servizi Fiscali sono attività principali di uno Studio di Dottori e Ragionieri
Commercialisti. Assicuriamo un’assistenza continuativa e competente che accompagna il cliente
durante l’intero svolgimento.`"
    />

    <SectionEmptyImage
        :image-name="'scrivendo.png'"
        :cards="cards"
    />
  </div>
</template>

<script>
import ServicesOffered from "@/components/services/ServicesOffered";
import SectionEmptyImage from "@/components/sections/section-image-empty/SectionEmptyImage";

export default {
  name: "ConsulenzaFiscaleContabile",
  components: {
    SectionEmptyImage,
    ServicesOffered
  },
  data() {
    return {
      cards: [
        {
          id: 1,
          title: 'consulenza del lavoro',
          iconName: 'ico-cons',
          url: '/services/consulenza-lavoro'
        },
        {
          id: 2,
          title: 'Caf Cgn',
          iconName: 'caf-blue-ico',
          url: '/services/caf-e-cgn'
        }
      ],
      services: [
        {
          id: 1,
          title: `CONTABILITA'`,
          description: 'Elaborazione ed aggiornamento di tutte le tipologie di Regimi Speciali, Regime\n' +
              'Forfettario, Regime Semplificato sia per Cassa che per Competenza ed infine il Regime Ordinario.',
          iconName: 'calcolatrice-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 2,
          title: `FATTURAZIONE ELETTRONICA`,
          description: 'Possibilità di importare direttamente le Fatture Elettroniche in contabilità, sia Attive che Passive;\n' +
              'Possibilità di predisporre per il Cliente la Fatturazione Attiva.',
          iconName: 'calcolatrice-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 3,
          title: `MODELLO F24`,
          description: 'Elaborazione, stampa ed invio telematico del MODELLO F24 con archiviazione\n' +
              'dello stesso e della relativa ricevuta telematica.',
          iconName: 'calcolatrice-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 4,
          title: `BILANCI`,
          description: 'Studio, elaborazione, stesura e deposito del Bilancio Annuale per le Società di Capitali e\n' +
              'Cooperative.',
          iconName: 'calcolatrice-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 5,
          title: `DICHIARAZIONI ANNUALI`,
          description: 'Elaborazione, stampa ed invio telematico di tutte le denunce Mensili ed Annuali\n' +
              'come ad esempio la Dichiarazione INAIL, la Certificazione Unica e il modello 730',
          iconName: 'calcolatrice-ico.svg',
          iconAlt: 'Icona calcolatrice'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>