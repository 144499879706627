<template>
  <div class="container-fluid">
    <div class="row">
      <section class="col-xl-4 bg-lite-white d-flex align-items-center" :class="withImage && 'p-0'">
        <div
            class="position-relative h-100 overflow-hidden"
            :class="withImage ? 'services-offered-small p-0' : 'services-offered p-3'"
        >
          <div class="position-relative text-center text-md-start overflow-hidden pt-md-5 left"
               :class="withImage && 'p-4 p-md-5'"
               style="z-index: 2">
            <h1 class="c-blue mb-3">
              {{ title }}
              <hr class="mt-2 mx-auto mx-md-0 c-dark-gray" />
            </h1>
            <p class="w-75 mx-auto mx-md-0 c-blue">
              {{ description }}
            </p>
          </div>
          <img
              :src="require(`@/assets/images/icons/${iconName}`)"
              class=" ico"
              width="175"
          />
        </div>
      </section>
      <div class="col-xl-8" :class="withImage ? 'p-0' : ''">
        <SectionServicesOffered
            :with-description="withImage"
            :services="services"
            :excerpt="excerpt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionServicesOffered from "@/components/sections/section-services-offered/SectionServicesOffered";
export default {
  name: "ServicesOffered",
  components: { SectionServicesOffered },
  props: {
    iconName: String,
    iconAlt: String,
    title: String,
    description: String,
    imageName: String,
    services: Array,
    withImage: Boolean(false),
    excerpt: String,
  },
}
</script>

<style scoped>
  .services-offered {
    min-height: 480px;
  }

  .services-offered-small {
    min-height: 400px;
  }

  h1 {
    line-height: 50px;
  }

  img.ico {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }

  .left {
    left: 0;
  }

  p {
    line-height: 32px;
    font-family: 'Sanchez', 'Syne', sans-serif;
  }

  hr {
    width: 50px;
    border: 1px solid;
  }

  @media screen and (min-width: 768px) {
    .left {
      left: 75px;
    }
  }

  @media screen and (min-width: 1200px) {
    .services-offered {
      min-height: 900px;
    }

    .services-offered-small {
      min-height: 600px;
    }

  }
</style>