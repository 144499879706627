<template>
  <section class="h-100">
    <div class="container-fluid h-100" :class="withDescription ? 'overflow-hidden p-0' : ''">
      <div v-if="withDescription" class="row">
        <div class="col-12">
          <article class="service-description p-5 d-flex align-items-center">
            <p v-if="excerpt" class="excerpt px-3 px-md-4 c-blue fw-bold">
              {{ excerpt }}
            </p>
          </article>
        </div>
      </div>
      <div class="row py-5 mt-4" :class="withDescription ? 'pe-4' : 'h-100'">
        <div v-for="service in services"
             class="col-md-6 d-flex justify-content-end justify-content-sm-center pe-md-0 mb-5"
             :key="service.id"
        >
          <article class="single-service position-relative">
            <img
                :src="require(`@/assets/images/icons/${service.iconName}`)"
                class="ico"
                :alt="service.iconAlt"
                width="45"
            />
            <h1 class="title fw-bold c-blue text-uppercase" v-html="service.title"></h1>
            <p class="description" v-html="service.description"></p>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionServicesOffered",
  props: {
    services: Array,
    withDescription: Boolean(false),
    excerpt: String,
  },
}
</script>

<style scoped>
  h1.title {
    font-size: 1.38rem;
  }

  .single-service {
    width: 300px;
  }

  img.ico {
    position: absolute;
    left: -58px;
  }

  p.excerpt {
    font-family: 'Sanchez', sans-serif;
  }

  .service-description {
    min-height: 280px;
    font-size: 0.8rem;
  }

  p.description {
    font-family: 'Sanchez', sans-serif;
  }

  @media screen and (min-width: 992px) {
    .service-description {
      font-size: 1.15rem;
    }
  }
</style>