<template>
  <div class="service-card box-shadow">
    <div class="center text-center">
      <router-link :to="url">
        <img
            :src="require(`@/assets/images/icons/${iconName}.svg`)"
            :alt="title + ' icon'"
            :width="width ? width : '100'"
            class="mb-3"
        />
        <h5 class="c-blue text-uppercase text-center fw-bold">
          {{ title }}
        </h5>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    title: String,
    url: String,
    iconName: String,
    width: Number
  }
}
</script>

<style scoped>
  .service-card {
    position: relative;
    z-index: 2;
    background-color: #fff;
    width: 230px;
    height: 300px;
    border-top-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  a { text-decoration: unset; }

</style>