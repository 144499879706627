<template>
  <div>
    <ServicesOffered
        :with-image="true"
        image-name="work.jpg"
        title="Servizi offerti"
        icon-name="ico-lavoro2.svg"
        :description="`
        Nel dettaglio comunicazioni e pratiche con la Direzione del Lavoro, l’INAIL, l’INPS e con le
Organizzazioni Sindacali dei lavoratori e dei datori di lavoro, per la regolarizzazione dei documenti.
L’area di attività professionale per i Dottori Commercialisti ed Esperti Contabili viene a loro attribuita dalla Legge 12/79 e
dall’ordinamento professionale.
        `"
        :services="services"
        :excerpt="`La consulenza in materia di lavoro si colloca in una posizione centrale tra imprese, istituzioni e
lavoratori. Lo Studio offre alle aziende clienti un supporto ampio, che comprende diversi aspetti
della stessa, passando per l&#39;elaborazione delle paghe e dei contributi e molto altro.`"
    />


    <SectionEmptyImage
        :image-name="'bg-section-default.jpg'"
        :cards="cards"
    />
  </div>
</template>

<script>
import ServicesOffered from "@/components/services/ServicesOffered";
import SectionEmptyImage from "@/components/sections/section-image-empty/SectionEmptyImage";

export default {
  name: "ConsulenzaLavoro",
  components: {
    SectionEmptyImage,
    ServicesOffered
  },
  data() {
    return {
      cards: [
        {
          id: 2,
          title: 'consulenza fiscale e contabile',
          iconName: 'calcolatrice-ico',
          url: '/services/consulenza-fiscale-contabile',
          width: 75,
        },
        {
          id: 2,
          title: 'Caf Cgn',
          iconName: 'caf-blue-ico',
          url: '/services/caf-e-cgn'
        }
      ],
      services: [
        {
          id: 1,
          title: `ELABORAZIONE PAGHE E CONTRIBUTI`,
          description: 'Elaborazione, stampa ed archiviazione Cedolino Paga Mensile con relativa denuncia UniEmens\n' +
              'all’INPS',
          iconName: 'ico-lavoro-blue.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 2,
          title: `CONSULENZA PREVIDENZIALE`,
          description: 'Gestione del Cassetto Previdenziale',
          iconName: 'ico-lavoro-blue.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 3,
          title: `CONSULENZA LAVORO`,
          description: 'Predisposizione pratiche per Assunzione o Cessazione di personale dipendente con le relative\n' +
              'Comunicazioni obbligatorie.',
          iconName: 'ico-lavoro-blue.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 4,
          title: `MODELLO F24`,
          description: 'Elaborazione, Stampe ed Invio telematico del MODELLO F24 con archiviazione dello stesso e della relativa ricevuta telematica.',
          iconName: 'ico-lavoro-blue.svg',
          iconAlt: 'Icona calcolatrice'
        },
        {
          id: 5,
          title: `DICHIARAZIONI ANNUALI`,
          description: 'Elaborazione, stampe ed invio Telematico di tutte le denunce Mensili ed Annuali, come ad esempio la Dichiarazione INAIL la Certificazione Unica e il Modello 770.\n' +
              '\n.',
          iconName: 'ico-lavoro-blue.svg',
          iconAlt: 'Icona calcolatrice'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>